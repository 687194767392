<template>
<!-- 客户子账号列表页 -->
    <div id="app_user_son_list">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                
            </div>
            <div class="head_top_title">子账号列表</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 0px;background-color: white;">
            <el-row style="overflow: auto;" :style="{height: (content_heigth+'px')}">
                <template v-for="item in tableData">
                    

                    <el-row class="dialog_top cursor" @click.native="son_detail(item.cpid)"  style="text-align: left;padding-left:15px;">
                        <el-col :span="14">
                            <span>{{item.cpid}}</span>
                        </el-col>

                        <el-col :span="10">
                            <el-row type="flex" justify="end" align="middle" style="margin-right:10px;">
                                <div v-if="item.child.length>0"  class="font_size6" 
                                    style="background-color: #009688;color:#fff;margin-top:10px;padding:0px 5px;height:24px;line-height:24px;border-radius: 2px;">
                                    {{item.child.length}}个子账户
                                </div>
                                <i class="dialog_top_close_button el-icon-arrow-right" style="font-size:24px"></i>
                            </el-row>
                        </el-col>
                    </el-row>
                    
                </template>

                

            </el-row>
        </el-row>
        

    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';

//import axios from "axios";
export default {

    data(){
        return{
            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度


            tableData:[],//客户子账号列表数据
            
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        this.tableData= this.$options.data().tableData;//单个恢复初始化
        
         //获取数据
        this.getData();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
        },

        //子账号的详情
        son_detail(cpid){
            console.log("子账号的详情"+cpid);
            var cpidOne = this.$route.params.id;
            var cpidTwo = this.$route.params.cpidTwo;
            var cpidThree = "000000";
            if(cpidTwo == "000000"){
                cpidTwo = cpid;
            }else{
                cpidThree = cpid;
            }
            API.router_to("/user_son_detail/"+cpidOne+"/"+cpidTwo+"/"+cpidThree);
        },


        //获取数据
        getData(){
            this.tableData = this.$options.data().tableData;//单个恢复初始化

            var cpidOne = this.$route.params.id;
            var cpidTwo = this.$route.params.cpidTwo;
            var cpid = cpidTwo;
            if(cpid == "000000"){
                cpid = cpidOne;
            }
            //请求接口
            API.UserServlet({
                param: "son_list",
                cpid:cpid,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.tableData = res.sonArray;
                }
            });
        },
        //返回
        goBack() {
            var cpidOne = this.$route.params.id;
            var cpidTwo = this.$route.params.cpidTwo;
            if(cpidTwo == "000000"){
                API.router_to("/user_detail/"+cpidOne);
            }else{
                API.router_to("/user_son_detail/"+cpidOne+"/"+cpidTwo+"/000000");
            }
        }
    }
};

</script>

<style scoped>

</style>